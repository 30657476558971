<template>
  <div class="firstBox">
    <el-form :model="dataInfo.ali" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
      <el-form-item label="支付宝支付：">
        <el-switch v-model="dataInfo.open_ali_pay" :active-value="1" :inactive-value="0"> </el-switch>
      </el-form-item>
      <el-form-item v-if="dataInfo.open_ali_pay === 1" label="支付宝AppID：" prop="app_id">
        <el-input v-model="dataInfo.ali.app_id"></el-input>
      </el-form-item>
      <el-form-item v-if="dataInfo.open_ali_pay === 1" label="公钥：" prop="public_key">
        <el-input v-model="dataInfo.ali.public_key"></el-input>
      </el-form-item>
      <el-form-item v-if="dataInfo.open_ali_pay === 1" label="私钥：" prop="private_key">
        <el-input v-model="dataInfo.ali.private_key"></el-input>
      </el-form-item>
    </el-form>
    <el-form :model="dataInfo.wx" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
      <el-form-item label="微信支付：">
        <el-switch v-model="dataInfo.open_wechat_pay" :active-value="1" :inactive-value="0"> </el-switch>
      </el-form-item>
      <el-form-item v-if="dataInfo.open_wechat_pay === 1" label="微信小程序AppID：" prop="app_id">
        <el-input v-model="dataInfo.wx.app_id"></el-input>
      </el-form-item>
      <el-form-item v-if="dataInfo.open_wechat_pay === 1" label="商户号Mch_ID：" prop="public_key">
        <el-input v-model="dataInfo.wx.m_id"></el-input>
      </el-form-item>
      <el-form-item v-if="dataInfo.open_wechat_pay === 1" label="支付秘钥APIkey：" prop="private_key">
        <el-input v-model="dataInfo.wx.api_key"></el-input>
      </el-form-item>
      <!-- <el-form-item v-if="dataInfo.open_wechat_pay === 1" label="referer：" prop="private_key">
        <el-input v-model="dataInfo.wx.referer" disabled placeholder="例如：www.cnweisou.net"></el-input>
      </el-form-item> -->
      <el-form-item v-if="dataInfo.open_wechat_pay === 1" label="apiclient_cert：" prop="apiclient_cert">
        <el-input disabled v-model="dataInfo.wx.apiclient_cert">
          <template slot="append">
            <el-button type="primary">
              上传文件
              <input type="file" ref="inputer0" @change="getFile(0)" />
            </el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item v-if="dataInfo.open_wechat_pay === 1" label="apiclient_key：" prop="apiclient_key">
        <el-input disabled v-model="dataInfo.wx.apiclient_key">
          <template slot="append">
            <el-button type="primary"
              >上传文件
              <input type="file" ref="inputer1" @change="getFile(1)" />
            </el-button>
          </template>
        </el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    dataInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      rules: {}
    }
  },
  methods: {
    // 上传文件
    getFile(status) {
      let files = null
      if (status == 0) {
        files = this.$refs.inputer0.files[0]
      } else if (status == 1) {
        files = this.$refs.inputer1.files[0]
      }
      let that = this
      let formData = new FormData()
      formData.append('cert', files)
      this.$axios.uploadFile(this.$api.upload.addCert, formData).then((res) => {
        if (res.code == 0) {
          that.$message.success('上传成功')
          if (status == 0) {
            this.$set(this.dataInfo.wx, 'apiclient_cert', res.result.path)
          } else {
            this.$set(this.dataInfo.wx, 'apiclient_key', res.result.path)
          }
        } else {
          that.$message.error(`${res.msg}`)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.firstBox {
  box-sizing: border-box;
  .el-form {
    .el-form-item {
      width: 900px;
      /deep/ .el-form-item__content .el-input {
        width: 400px;
        .el-button {
          color: #fff !important;
          background-color: #409eff !important;
          border-color: #409eff !important;
          border-radius: 0;
          position: relative;
          input {
            opacity: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
          }
        }
      }
    }
  }
}
</style>
