<template>
  <div>
    <div class="steps" v-for="(item, index) in authorizationArr" :key="index">
      <div class="top">
        <div class="left" v-if="authorizationSteps <= index" :style="{ background: authorizationSteps === index ? '#2774EE' : '#D8D8D8' }">
          {{ index + 1 }}
        </div>
        <div class="left1" v-else>
          <i class="el-icon-check"></i>
        </div>
        <div class="mid">
          {{ item.stageTitle }}
          <div v-if="index == 2" class="copyParams" @click="copyId($event)">复制打包参数</div>
        </div>
        <div class="right">
          <div
            v-if="authorizationSteps <= index"
            class="canBtn"
            :style="{
              color: authorizationSteps === index ? '#fff' : '#999999',
              background: authorizationSteps === index ? '#2774EE' : '#EBEBEB',
              cursor: index == 2 ? 'auto' : '',
            }"
            @click="btnFunction(index)"
          >
            {{ item.btnTxt }}
          </div>
          <div v-else class="rightElse">
            <div v-if="index === 0">
              <i class="el-icon-check"></i>
              已配置
            </div>
            <div v-if="index === 1">
              <i class="el-icon-check"></i>
              已配置
            </div>
            <div v-if="index === 2">
              <i class="el-icon-check"></i>
              已发布
            </div>
          </div>
        </div>
      </div>
      <div class="bot">
        <div class="left" v-if="index !== authorizationArr.length - 1">
          <div class="xian" :style="{ background: authorizationSteps >= index + 1 ? '#2774EE' : '#eeeeee' }"></div>
        </div>
        <div class="mid">
          <div v-if="index === 0">
            <p>在华为开发者后台，申请完成快应用后，信息添加到基础设置中。</p>
            <!-- <p v-if="dataInfo.name">抖音小程序名称：{{ dataInfo.name }}</p> -->
          </div>
          <div v-if="index === 1">
            <p></p>
          </div>
          <div v-if="index === 2">
            <p style="padding-left: 24px">华为快应用不支持第三方上传，需要登录华为开发者后台操作。</p>
          </div>
        </div>
        <div class="right">
          <div v-if="index === 0 && authorizationSteps > 0">
            <span @click="lookOne(index)">查看</span>
          </div>
          <div v-if="index === 1 && authorizationSteps > 1">
            <span @click="lookOne(index)">查看</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="基础设置" :visible.sync="basicFlag" width="52%">
      <Basic ref="Basic" @updateBasicsSu="updateBasicsSu" :dataInfo="dataInfo" :isHave="isHave" :authorizationSteps="authorizationSteps"></Basic>
      <span slot="footer" class="dialog-footer">
        <el-button @click="basicFlag = false">取 消</el-button>
        <el-button type="primary" @click="preservation(0)">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="支付设置" :visible.sync="PayConfigFlag" width="52%">
      <PayConfig ref="PayConfig" @updatePaySu="updatePaySu" :dataInfo="dataInfo" :authorizationSteps="authorizationSteps"></PayConfig>
      <span slot="footer" class="dialog-footer">
        <el-button @click="PayConfigFlag = false">取 消</el-button>
        <el-button type="primary" @click="preservation(1)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Basic from './quickBasics.vue';
import PayConfig from './quickPay.vue';
import clipboard from '@/util/clipBoard.js';
export default {
  components: {
    Basic,
    PayConfig,
  },
  data() {
    return {
      // 支付设置弹框
      PayConfigFlag: false,
      // 基础设置弹框
      basicFlag: false,
      // 步骤状态
      authorizationSteps: 0,
      // 审核步骤合集
      authorizationArr: [
        { stageTitle: '添加华为快应用信息', btnTxt: '立即配置' }, // 表单输入 小程序名字 appid 秘钥
        { stageTitle: '基础设置成功，立刻支付配置', btnTxt: '立即配置' },
        { stageTitle: '配置成功，在华为开发者后台设立账号，并上传快应用', btnTxt: '等待上传' },
      ],
      dataInfo: {},
      isHave: true,
      id: -1,
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    // 获取抖音信息
    getInfo() {
      let that = this;
      this.$axios
        .post(this.$api.set.Settinginfo, {
          type: 4,
        })
        .then(res => {
          if (res.code == 0) {
            if (res.result.info == '') {
              that.isHave = false;
              that.dataInfo = {
                app_id: '',
                logo: '',
                company: '',
                mobile: '',
                name: '',
                packagename: '',
                isSecond: 0,
                open_ali_pay: 1,
                open_wechat_pay: 1,
                ali: {
                  app_id: '',
                  public_key: '',
                  private_key: '',
                },
                wx: {
                  app_id: '',
                  m_id: '',
                  api_key: '',
                  referer: 'https://testdigitalize.cnweisou.net',
                  apiclient_cert: '',
                  apiclient_key: '',
                },
              };
              return;
            }

            that.id = res.result.info.id;
            that.dataInfo = res.result.info.sets;
            this.chenkSteps();
            if (res.result.info.sets.app_id === undefined) {
              // 如果第一步没填
              that.dataInfo.app_id = '';
              that.dataInfo.logo = '';
              that.dataInfo.company = '';
              that.dataInfo.mobile = '';
              that.dataInfo.name = '';
              that.dataInfo.packagename = '';
              this.chenkSteps();
              return;
            }
            if (res.result.info.sets.ali.app_id == '') {
              // 如果第二步没填
              that.dataInfo.isSecond = 0;
              that.dataInfo.open_ali_pay = 1;
              that.dataInfo.open_wechat_pay = 1;
              that.dataInfo.ali = {
                app_id: '',
                public_key: '',
                private_key: '',
              };
              that.dataInfo.wx = {
                app_id: '',
                m_id: '',
                api_key: '',
                referer: 'https://testdigitalize.cnweisou.net',
                apiclient_cert: '',
                apiclient_key: '',
              };
              this.chenkSteps();
              return;
            }
            // if(this.){}
            that.dataInfo.wx.referer = 'https://testdigitalize.cnweisou.net';
          }
        });
    },
    // 点击配置按钮
    btnFunction(index) {
      if (index == 0) {
        if (this.authorizationSteps == 0) {
          this.basicFlag = true;
        }
      } else if (index == 1) {
        if (this.authorizationSteps == 1) {
          this.PayConfigFlag = true;
        }
      }
    },
    preservation(status) {
      if (!status) {
        if (this.dataInfo.app_id == '' || this.dataInfo.logo == '' || this.dataInfo.company == '' || this.dataInfo.mobile == '' || this.dataInfo.name == '') {
          this.$message.warning('请将所有数据填写完整');
          return;
        }
      } else {
        if (!this.dataInfo.open_ali_pay && !this.dataInfo.open_wechat_pay) {
          this.$message.warning('必须配置一个支付数据');
          return;
        }
        if (this.dataInfo.open_ali_pay) {
          if (
            this.dataInfo.ali.app_id == null ||
            this.dataInfo.ali.public_key == null ||
            this.dataInfo.ali.private_key == null ||
            this.dataInfo.ali.app_id == '' ||
            this.dataInfo.ali.public_key == '' ||
            this.dataInfo.ali.private_key == ''
          ) {
            this.$message.warning('请将支付宝支付的所有数据填写完整');
            return;
          }
        }
        if (this.dataInfo.open_wechat_pay) {
          if (
            this.dataInfo.wx.app_id == null ||
            this.dataInfo.wx.m_id == null ||
            this.dataInfo.wx.api_key == null ||
            this.dataInfo.wx.apiclient_cert == null ||
            this.dataInfo.wx.apiclient_key == null ||
            this.dataInfo.wx.referer == null ||
            this.dataInfo.wx.app_id == '' ||
            this.dataInfo.wx.m_id == '' ||
            this.dataInfo.wx.api_key == '' ||
            this.dataInfo.wx.apiclient_cert == '' ||
            this.dataInfo.wx.apiclient_key == '' ||
            this.dataInfo.wx.referer == ''
          ) {
            this.$message.warning('请将微信支付的所有数据填写完整');
            return;
          }
        }
      }
      let that = this;
      if (status == 1) {
        this.dataInfo.isSecond = 1;
      }
      let sets = { ...this.dataInfo };
      let url = '';
      let params = {
        sets,
        type: 4,
      };
      if (this.isHave) {
        url = this.$api.set.Settingedit;
        params.id = this.id;
      } else {
        url = this.$api.set.Settingadd;
      }
      this.$axios.post(url, params).then(res => {
        if (res.code == 0) {
          that.$message({
            message: '保存成功',
            type: 'success',
          });
          that.isHave = true;
          if (!status) {
            that.basicFlag = false;
          } else {
            that.PayConfigFlag = false;
          }
          this.chenkSteps();
          return;
        }
        that.$message({
          message: '保存失败',
          type: 'error',
        });
      });
    },
    lookOne(index) {
      if (index == 0) {
        this.basicFlag = true;
      } else if (index == 1) {
        this.PayConfigFlag = true;
      }
    },
    chenkSteps() {
      if (this.dataInfo.app_id == '') {
        this.authorizationSteps = 0;
      } else if (!this.dataInfo.isSecond) {
        this.authorizationSteps = 1;
      } else if (this.dataInfo.isSecond === 1) {
        this.authorizationSteps = 2;
      } else if (this.dataInfo.isSecond === 2) {
        this.authorizationSteps = 3;
      }
    },
    copyId(e) {
      let id = Number(localStorage.getItem('u_id'));
      clipboard(e, id);
    },
  },
};
</script>

<style lang="less" scoped>
.cupo {
  cursor: pointer;
}
.fc666 {
  color: #666 !important;
}
.fc409 {
  color: #409eff;
}
.fc999 {
  color: #999 !important;
}
.fc852 {
  color: #2774ee !important;
  cursor: pointer;
}
.canBtn {
  width: 96px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  border-radius: 2px;
  cursor: pointer;
  user-select: none;
}
.steps {
  width: 100%;
  & > div {
    display: flex;
    justify-content: space-between;
    .left {
      flex: 0 0 24px;
    }
    .mid {
      flex: 1;
      padding: 0 10px;
    }
    .right {
      flex: 0 0 96px;
    }
  }
  .top {
    align-items: center;
    .left {
      height: 24px;
      text-align: center;
      line-height: 24px;
      color: #fff;
      border-radius: 50%;
    }
    .left1 {
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 22px;
      border: 2px solid #2774ee;
      border-radius: 50%;
      i {
        color: #2774ee;
        font-weight: bold;
      }
    }
    .mid {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      display: flex;
      align-items: center;
    }
    .rightElse {
      flex-wrap: nowrap;
      & > div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        i {
          width: 16px;
          height: 16px;
          background: #2774ee;
          border-radius: 50%;
          color: #fff;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          font-weight: bold;
          margin-right: 8px;
        }
      }
    }
  }
  .bot {
    min-height: 100px;
    .left {
      .xian {
        width: 2px;
        min-height: 80px;
        margin: 10px auto;
      }
    }
    .mid {
      & > div {
        p {
          font-size: 14px;
          color: #666;
          margin-top: 5px;
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      div {
        text-align: right;
      }
      span {
        cursor: pointer;
        user-select: none;
        align-self: flex-end;
        font-size: 14px;
        color: #2774ee;
      }
    }
  }
  .bot:last-child {
    min-height: 50px;
  }
}
.el-table {
  margin-bottom: 10px;
  /deep/ .el-table__body-wrapper {
    height: 40vh;
    overflow: hidden;
    overflow-y: auto;
  }
}
.descBox {
  display: flex;
  .title {
    width: 120px;
    text-align: right;
    flex-shrink: 0;
  }
  .el-textarea {
    /deep/ .el-textarea__inner {
      resize: none;
    }
  }
  .tips {
    width: calc(100% - 120px);
    color: red;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
